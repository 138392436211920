import { Code, Em, Strong, Text as RadixText, TextProps as RadixTextProps } from "@radix-ui/themes";
import React, { forwardRef } from "react";

type TextProps = RadixTextProps & {
  underline?: boolean;
  bold?: boolean;
  italic?: boolean;
  code?: boolean;
  inline?: boolean;
};

export const Text = forwardRef<HTMLSpanElement, TextProps>(
  ({ underline, bold, italic, code, size = "2", ...props }: TextProps, forwardedRef) => {
    const wrapper = () => {
      switch (true) {
        case bold:
          return Strong;
        case italic:
          return Em;
        case code:
          return Code;
        default:
          return null;
      }
    };

    const Wrapper = wrapper();
    return (
      <RadixText
        size={size}
        style={{ textDecoration: underline ? "underline" : undefined }}
        {...props}
        ref={forwardedRef}
      >
        {Wrapper ? <Wrapper size={size}>{props.children}</Wrapper> : props.children}
      </RadixText>
    );
  },
);
