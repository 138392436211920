import { DataList } from "@radix-ui/themes";
import React, { forwardRef } from "react";

type DataListProps = DataList.RootProps;

const DataListRoot = forwardRef<HTMLDListElement, DataListProps>(({ ...props }, forwardedRef) => {
  return <DataList.Root {...props} ref={forwardedRef} />;
});

const DataListItem = forwardRef<HTMLDivElement, DataList.ItemProps>(({ ...props }, forwardedRef) => {
  return <DataList.Item {...props} ref={forwardedRef} />;
});

const DataListLabel = forwardRef<HTMLLabelElement, DataList.LabelProps>(({ ...props }, forwardedRef) => {
  return <DataList.Label {...props} ref={forwardedRef} />;
});

const DataListValue = forwardRef<HTMLDivElement, DataList.ValueProps>(({ ...props }, forwardedRef) => {
  return <DataList.Value {...props} ref={forwardedRef} />;
});

export { DataListRoot as Root, DataListItem as Item, DataListLabel as Label, DataListValue as Value };
