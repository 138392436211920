import { Spinner as RadixSpinner, SpinnerProps as RadixSppinerProps } from "@radix-ui/themes";
import React from "react";
import { forwardRef } from "react";

type SwitchProps = RadixSppinerProps & {
  size?: "1" | "2" | "3";
  loading?: boolean;
};

export const Spinner = forwardRef<HTMLElement, SwitchProps>(({ size, loading, ...props }) => {
  return <RadixSpinner size={size} loading={loading} {...props} />;
});
