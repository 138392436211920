import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import React from "react";
import { DayPicker } from "react-day-picker";
import "./Calendar.css";
import { Day } from "./components/Day";

export type CalendarProps = React.ComponentProps<typeof DayPicker> & {
  direction?: "row" | "column";
};

function Calendar({ className, classNames, showOutsideDays = true, direction, ...props }: CalendarProps) {
  return (
    <DayPicker
      className={className}
      classNames={{
        months: `${direction === "column" ? "calendar-months" : "calendar-months-row"}`,
        month: "calendar-month",
        caption: "calendar-caption",
        caption_label: "calendar-caption-label",
        nav: "calendar-nav",
        nav_button: "calendar-nav-button",
        nav_button_previous: "calendar-nav-button-previous",
        nav_button_next: "calendar-nav-button-next",
        table: "calendar-table",
        head_row: "calendar-head-row",
        head_cell: "calendar-head-cell",
        row: "calendar-row",
        cell: props.mode === "range" ? "calendar-cell-range" : "calendar-cell",
        day: "calendar-day",
        day_range_start: "calendar-day-range-start",
        day_range_end: "calendar-day-range-end",
        day_selected: "calendar-day-selected",
        day_today: "calendar-day-today",
        day_outside: "calendar-day-outside",
        day_disabled: "calendar-day-disabled",
        day_range_middle: "calendar-day-range-middle",
        day_hidden: "calendar-day-hidden",
        ...classNames,
      }}
      showOutsideDays={showOutsideDays}
      components={{
        IconLeft: ({ ...props }) => <ChevronLeftIcon />,
        IconRight: ({ ...props }) => <ChevronRightIcon />,
        Day: ({ ...props }) => <Day {...props} />,
      }}
      {...props}
    />
  );
}

Calendar.displayName = "Calendar";

export { Calendar };
