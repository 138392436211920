import { Tooltip as RadixTooltip, TooltipProps as RadixTooltipProps } from "@radix-ui/themes";
import React from "react";
import { forwardRef } from "react";

type TooltipProps = RadixTooltipProps & {
  label?: string;
  labelPosition?: "top" | "left";
};

export const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(({ label, labelPosition, ...props }, forwardedRef) => {
  return <RadixTooltip ref={forwardedRef} {...props} />;
});
