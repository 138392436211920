import { HoverCard as RadixHoverCard } from "@radix-ui/themes";
import React, { forwardRef } from "react";

// Define a root component for the HoverCard, wrapping RadixHoverCard.Root
type HoverCardRootProps = RadixHoverCard.RootProps;
const HoverCardRoot = ({ ...props }: HoverCardRootProps) => {
  return <RadixHoverCard.Root {...props} />;
};

// Define the trigger component, forwarding the ref
const HoverCardTrigger = forwardRef<HTMLAnchorElement, RadixHoverCard.TriggerProps>(({ ...props }, ref) => {
  return <RadixHoverCard.Trigger {...props} ref={ref} />;
});

// Extend HoverCardContentProps to include backgroundColor, boxShadow, and padding
interface ExtendedHoverCardContentProps extends RadixHoverCard.ContentProps {
  backgroundColor?: string;
  boxShadow?: string;
  padding?: string;
}

// Define the content component, forwarding the ref and accepting custom styles
const HoverCardContent = forwardRef<HTMLDivElement, ExtendedHoverCardContentProps>(({ ...props }, ref) => {
  return <RadixHoverCard.Content {...props} ref={ref} />;
});

// Exporting components with aliases for a consistent API
export { HoverCardRoot as Root, HoverCardTrigger as Trigger, HoverCardContent as Content };
